import React, { useState, useEffect } from "react";
import {
  ReactiveBase,
  ReactiveComponent,
  DataSearch,
  MultiDropdownList,
  SelectedFilters,
  ReactiveList,
  ResultCard,
  RangeSlider,
} from "@appbaseio/reactivesearch";
import { Row, Col, Card, Divider, Switch } from "antd";
import "antd/dist/antd.min.css";
import * as cookieUtils from "../assets/cookie-utils.js";
import getESRanges from "../services/elasticService.js";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import styles from "./Search.module.css";
import config from "../config/config.js";
import * as constants from "../assets/constants.js";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as query from "./es-queries/elasticQueries.js";
import moreIcon from "../assets/imgs/arrowDown.png";
import lessIcon from "../assets/imgs/arrowUp.png";
import { Box, TextField } from "@material-ui/core";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import Button from "@mui/material/Button";
import ESDateRange from "./es-date-range/ESDateRange.js";
import LinearProgress from "@mui/material/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import dayjs from "dayjs";
import NumberFormat from "react-number-format";

const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("en-US");
dayjs.tz.setDefault("America/New_York");

const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#495057",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#495057",
    fontSize: "13px",
  },
}));

export default function ElasticSearch() {
  let initialUserInfo = {
    userType: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    status: "",
    role: "",
    office: "",
    userKey: 0,
    clientKey: 0,
    reportLevel: "",
    reportEntities: "",
  };
  let defaultFromDate;
  let defaultToDate;
  let wildCardQuery, exactMatchQuery, initialQuery, rangeFilterQuery;
  let componentIdsSync = [
    "CallDisposition",
    "AgentName",
    "Account",
    "Opportunity",
    "Stage",
    "KPI",
    "CampaignName",
    "CallSource",
    "Speaker",
    "ParticipantName",
    "HostName",
    "Topic",
    "CallDuration",
    "Talktime",
    "SpeakPercent",
    "GryphScore",
    "Search",
    "DateRange",
    "ClientName",
    "RegionName",
    "TeamName",
    "OfficeName",
  ];

  const [userInfo, setUserInfo] = React.useState(initialUserInfo);
  const [openDetails, setOpenDetails] = useState(false);
  const [activeId, setActiveid] = useState("");
  const [conversationRedirect] = React.useState(" ");
  const [endpoint, setEndpoint] = useState("");
  const [indexEnv, setIndexEnv] = useState("");
  const [statsCount, setStatsCount] = useState(0);
  const [selectedFromDate, setSelectedFromDate] = React.useState(defaultFromDate);
  const [selectedToDate, setSelectedToDate] = React.useState(defaultToDate);
  const [refreshDate, setRefreshDate] = React.useState(false);
  const [changeQuery, setChangeQuery] = useState(false);
  const [filterBool, setFilterBool] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [rangeTalktime, setRangeTalktime] = useState({
    start: 0,
    end: 100,
  });
  const [rangeConvLength, setRangeConvLength] = useState({
    start: 0,
    end: 100,
  });
  const [rangeSpeakPercent] = useState({
    start: 0,
    end: 100,
  });
  const rangeGryphScore = {
    start: 0,
    end: 100,
  };
  const [valConvLength, setValConvLength] = useState({
    start: 0,
    end: 100,
  });
  const [valTalktime, setValTalktime] = useState({
    start: 0,
    end: 100,
  });
  const [valSpeakPercent, setValSpeakPercent] = useState({
    start: 0,
    end: 100,
  });
  const [valGryphScore, setValGryphScore] = useState({
    start: 0,
    end: 100,
  });
  let timerObj = 0;
  const serverURL = config.PCA_SERVER_URL;
  const indexName = indexEnv + "*";

  useEffect(() => {
    (async () => {
      try {
        let activeUser = await cookieUtils.fetchActiveUserSession();
        setUserInfo(activeUser);
      } catch (err) {}
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        // fetch es ranges for slider driven components
        let rangeTalktimeRes = await getESRanges({ filter: rangeFilterQuery, fieldVal: "speakDuration" });
        setRangeTalktime(rangeTalktimeRes?.data);
        setValTalktime(rangeTalktimeRes?.data);
        let rangeConvLengthRes = await getESRanges({ filter: rangeFilterQuery, fieldVal: "CallDuration" });
        setRangeConvLength(rangeConvLengthRes?.data);
        setValConvLength(rangeConvLengthRes?.data);
      } catch (err) {}
    })();
  }, [rangeFilterQuery, userInfo]);

  useEffect(() => {
    (async () => {
      try {
        await fetch(`${serverURL}elasticEndpoint`)
          .then((res) => res.json())
          .then((res) => {
            setEndpoint(res.data.endpoint);
            setIndexEnv(res.data.elasticEnvironment);
          });
      } catch (err) {}
    })();
  }, []);

  switch (userInfo.reportLevel) {
    case "gryphon":
      wildCardQuery = (value) => query.wildCardQueryReportLevelGryphon(value);
      exactMatchQuery = (value) => query.exactMatchQueryReportLevelGryphon(value);
      initialQuery = (value) => query.defaultQueryReportLevelGryphon(value);
      rangeFilterQuery = query.getRangeFilterQuery(userInfo.reportLevel, "");
      break;
    case "client":
      wildCardQuery = (value) => query.wildCardQueryReportLevelClient(value, userInfo.reportEntities);
      exactMatchQuery = (value) => query.exactMatchQueryReportLevelClient(value, userInfo.reportEntities);
      initialQuery = () => query.defaultQueryReportLevelClient(userInfo.reportEntities);
      rangeFilterQuery = query.getRangeFilterQuery(userInfo.reportLevel, userInfo.clientKey);
      break;
    case "region":
      wildCardQuery = (value) => query.wildCardQueryReportLevelRegion(value, userInfo.reportEntities);
      exactMatchQuery = (value) => query.exactMatchQueryReportLevelRegion(value, userInfo.reportEntities);
      initialQuery = () => query.defaultQueryReportLevelRegion(userInfo.reportEntities);
      rangeFilterQuery = query.getRangeFilterQuery(userInfo.reportLevel, userInfo.reportEntities);
      break;
    case "team":
      wildCardQuery = (value) => query.wildCardQueryReportLevelTeam(value, userInfo.reportEntities);
      exactMatchQuery = (value) => query.exactMatchQueryReportLevelTeam(value, userInfo.reportEntities);
      initialQuery = () => query.defaultQueryReportLevelTeam(userInfo.reportEntities);
      rangeFilterQuery = query.getRangeFilterQuery(userInfo.reportLevel, userInfo.reportEntities);
      break;
    case "office":
      wildCardQuery = (value) => query.wildCardQueryReportLevelOffice(value, userInfo.reportEntities);
      exactMatchQuery = (value) => query.exactMatchQueryReportLevelOffice(value, userInfo.reportEntities);
      initialQuery = () => query.defaultQueryReportLevelOffice(userInfo.reportEntities);
      rangeFilterQuery = query.getRangeFilterQuery(userInfo.reportLevel, userInfo.reportEntities);
      break;
    case "agent":
      wildCardQuery = (value) => query.wildCardQueryReportLevelAgent(value, userInfo.reportEntities);
      exactMatchQuery = (value) => query.exactMatchQueryReportLevelAgent(value, userInfo.reportEntities);
      initialQuery = () => query.defaultQueryReportLevelAgent(userInfo.reportEntities);
      rangeFilterQuery = query.getRangeFilterQuery(userInfo.reportLevel, userInfo.userKey);
      break;
    default:
      wildCardQuery = (value) => query.wildCardQueryReportLevelNone(value);
      exactMatchQuery = (value) => query.exactMatchQueryReportLevelNone(value);
      initialQuery = () => query.defaultQueryReportLevelNone();
      rangeFilterQuery = query.getRangeFilterQuery("default", "");
      break;
  }

  // CallDuration
  const handleConvLengthValueChange = (val) => {
    if (val) {
      let newStart = isNaN(val[0]) || "" ? 0 : val[0];
      let newEnd = isNaN(val[1]) || "" ? 0 : val[1];
      setValConvLength({ start: newStart, end: newEnd });
    }
  };

  const handleStartTimeChange = (e) => {
    clearTimeout(timerObj);
    let formattedValue = e.floatValue;
    const prevValue = JSON.parse(JSON.stringify(valConvLength));
    if (
      formattedValue &&
      formattedValue !== prevValue.start &&
      formattedValue <= valConvLength.end &&
      formattedValue >= rangeConvLength.start &&
      formattedValue <= rangeConvLength.end
    ) {
      timerObj = setTimeout(() => {
        const formattedValueParsed = parseInt(formattedValue);
        setValConvLength({ start: formattedValueParsed, end: prevValue.end });
      }, 500);
    }
  };

  const blurStartTimeChange = (e) => {
    clearTimeout(timerObj);
    timerObj = setTimeout(() => {
      let val = e.target.value;
      let prevValue = JSON.parse(JSON.stringify(valConvLength));
      if (val) {
        let formattedValue = val;
        let formattedValueParsed = parseInt(formattedValue);
        if (formattedValueParsed <= valConvLength.end && formattedValue >= rangeConvLength.start && formattedValue <= rangeConvLength.end) {
          setValConvLength({ start: formattedValueParsed, end: prevValue.end });
        } else {
          setValConvLength({ start: 0, end: prevValue.end });
        }
      } else {
        setValConvLength({ start: 0, end: prevValue.end });
      }
    }, 500);
  };

  const handleEndTimeChange = (e) => {
    clearTimeout(timerObj);
    let formattedValue = e.floatValue;
    const prevValue = JSON.parse(JSON.stringify(valConvLength));
    if (
      formattedValue &&
      formattedValue !== prevValue.end &&
      formattedValue >= valConvLength.start &&
      formattedValue >= rangeConvLength.start &&
      formattedValue <= rangeConvLength.end
    ) {
      timerObj = setTimeout(() => {
        const formattedValueParsed = parseInt(formattedValue);
        setValConvLength({ start: prevValue.start, end: formattedValueParsed });
      }, 500);
    }
  };

  const blurEndTimeChange = (e) => {
    clearTimeout(timerObj);
    timerObj = setTimeout(() => {
      let val = e.target.value;
      let prevValue = JSON.parse(JSON.stringify(valConvLength));
      if (val) {
        let formattedValue = val;
        let formattedValueParsed = parseInt(formattedValue);
        if (formattedValueParsed >= valConvLength.start && formattedValue >= rangeConvLength.start && formattedValue <= rangeConvLength.end) {
          setValConvLength({ start: prevValue.start, end: formattedValueParsed });
        } else {
          setValConvLength({ start: prevValue.start, end: rangeConvLength.end });
        }
      } else {
        setValConvLength({ start: prevValue.start, end: rangeConvLength.end });
      }
    }, 500);
  };
  //

  // Talktime
  const handleTalktimeRangeValueChange = (val) => {
    if (val) {
      let newStart = isNaN(val[0]) || "" ? 0 : val[0];
      let newEnd = isNaN(val[1]) || "" ? 0 : val[1];
      setValTalktime({ start: newStart, end: newEnd });
    }
  };

  const handleTalktimeStartChange = (e) => {
    clearTimeout(timerObj);
    let formattedValue = e.floatValue;
    const prevValue = JSON.parse(JSON.stringify(valTalktime));
    if (
      formattedValue &&
      formattedValue !== prevValue.start &&
      formattedValue <= valTalktime.end &&
      formattedValue >= rangeTalktime.start &&
      formattedValue <= rangeTalktime.end
    ) {
      timerObj = setTimeout(() => {
        const formattedValueParsed = parseInt(formattedValue);
        setValTalktime({ start: formattedValueParsed, end: prevValue.end });
      }, 500);
    }
  };

  const blurTalktimeStartChange = (e) => {
    clearTimeout(timerObj);
    timerObj = setTimeout(() => {
      let val = e.target.value;
      let prevValue = JSON.parse(JSON.stringify(valTalktime));
      if (val) {
        let formattedValue = val;
        let formattedValueParsed = parseInt(formattedValue);
        if (formattedValueParsed <= valTalktime.end && formattedValue >= rangeTalktime.start && formattedValue <= rangeTalktime.end) {
          setValTalktime({ start: formattedValueParsed, end: prevValue.end });
        } else {
          setValTalktime({ start: 0, end: prevValue.end });
        }
      } else {
        setValTalktime({ start: 0, end: prevValue.end });
      }
    }, 500);
  };

  const handleTalktimeEndChange = (e) => {
    clearTimeout(timerObj);
    let formattedValue = e.floatValue;
    const prevValue = JSON.parse(JSON.stringify(valTalktime));
    if (
      formattedValue &&
      formattedValue !== prevValue.end &&
      formattedValue >= valTalktime.start &&
      formattedValue >= rangeTalktime.start &&
      formattedValue <= rangeTalktime.end
    ) {
      timerObj = setTimeout(() => {
        const formattedValueParsed = parseInt(formattedValue);
        setValTalktime({ start: prevValue.start, end: formattedValueParsed });
      }, 500);
    }
  };

  const blurTalktimeEndChange = (e) => {
    clearTimeout(timerObj);
    timerObj = setTimeout(() => {
      let val = e.target.value;
      let prevValue = JSON.parse(JSON.stringify(valTalktime));
      if (val) {
        let formattedValue = val;
        let formattedValueParsed = parseInt(formattedValue);
        if (formattedValueParsed >= valTalktime.start && formattedValue >= rangeTalktime.start && formattedValue <= rangeTalktime.end) {
          setValTalktime({ start: prevValue.start, end: formattedValueParsed });
        } else {
          setValTalktime({ start: prevValue.start, end: rangeTalktime.end });
        }
      } else {
        setValTalktime({ start: prevValue.start, end: rangeTalktime.end });
      }
    }, 500);
  };
  //

  // SpeakPercent
  const handleSpeakPercentRangeValueChange = (val) => {
    if (val) {
      let newStart = isNaN(val[0]) || "" ? 0 : val[0];
      let newEnd = isNaN(val[1]) || "" ? 0 : val[1];
      setValSpeakPercent({ start: newStart, end: newEnd });
    }
  };

  const handleSpeakPercentStartChange = (e) => {
    clearTimeout(timerObj);
    let formattedValue = e.floatValue;
    const prevValue = JSON.parse(JSON.stringify(valSpeakPercent));
    if (
      formattedValue &&
      formattedValue !== prevValue.start &&
      formattedValue <= valSpeakPercent.end &&
      formattedValue >= rangeSpeakPercent.start &&
      formattedValue <= rangeSpeakPercent.end
    ) {
      timerObj = setTimeout(() => {
        const formattedValueParsed = parseInt(formattedValue);
        setValSpeakPercent({ start: formattedValueParsed, end: prevValue.end });
      }, 500);
    }
  };

  const blurSpeakPercentStartChange = (e) => {
    clearTimeout(timerObj);
    timerObj = setTimeout(() => {
      let val = e.target.value;
      let prevValue = JSON.parse(JSON.stringify(valSpeakPercent));
      if (val) {
        let formattedValue = val;
        let formattedValueParsed = parseInt(formattedValue);
        if (formattedValueParsed <= valSpeakPercent.end && formattedValue >= rangeSpeakPercent.start && formattedValue <= rangeSpeakPercent.end) {
          setValSpeakPercent({ start: formattedValueParsed, end: prevValue.end });
        } else {
          setValSpeakPercent({ start: 0, end: prevValue.end });
        }
      } else {
        setValSpeakPercent({ start: 0, end: prevValue.end });
      }
    }, 500);
  };

  const handleSpeakPercentEndChange = (e) => {
    clearTimeout(timerObj);
    let formattedValue = e.floatValue;
    const prevValue = JSON.parse(JSON.stringify(valSpeakPercent));
    if (
      formattedValue &&
      formattedValue !== prevValue.end &&
      formattedValue >= valSpeakPercent.start &&
      formattedValue >= rangeSpeakPercent.start &&
      formattedValue <= rangeSpeakPercent.end
    ) {
      timerObj = setTimeout(() => {
        const formattedValueParsed = parseInt(formattedValue);
        setValSpeakPercent({ start: prevValue.start, end: formattedValueParsed });
      }, 500);
    }
  };

  const blurSpeakPercentEndChange = (e) => {
    clearTimeout(timerObj);
    timerObj = setTimeout(() => {
      let val = e.target.value;
      let prevValue = JSON.parse(JSON.stringify(valSpeakPercent));
      if (val) {
        let formattedValue = val;
        let formattedValueParsed = parseInt(formattedValue);
        if (formattedValueParsed >= valSpeakPercent.start && formattedValue >= rangeSpeakPercent.start && formattedValue <= rangeSpeakPercent.end) {
          setValSpeakPercent({ start: prevValue.start, end: formattedValueParsed });
        } else {
          setValSpeakPercent({ start: prevValue.start, end: rangeSpeakPercent.end });
        }
      } else {
        setValSpeakPercent({ start: prevValue.start, end: rangeSpeakPercent.end });
      }
    }, 500);
  };
  //

  // GryphScore
  const handleGryphScoreRangeValueChange = (val) => {
    if (val) {
      let newStart = isNaN(val[0]) || "" ? 0 : val[0];
      let newEnd = isNaN(val[1]) || "" ? 0 : val[1];
      setValGryphScore({ start: newStart, end: newEnd });
    }
  };

  const handleGryphScoreStartChange = (e) => {
    clearTimeout(timerObj);
    let formattedValue = e.floatValue;
    const prevValue = JSON.parse(JSON.stringify(valGryphScore));
    if (
      formattedValue &&
      formattedValue !== prevValue.start &&
      formattedValue <= valGryphScore.end &&
      formattedValue >= rangeGryphScore.start &&
      formattedValue <= rangeGryphScore.end
    ) {
      timerObj = setTimeout(() => {
        const formattedValueParsed = parseInt(formattedValue);
        setValGryphScore({ start: formattedValueParsed, end: prevValue.end });
      }, 500);
    }
  };

  const blurGryphScoreStartChange = (e) => {
    clearTimeout(timerObj);
    timerObj = setTimeout(() => {
      let val = e.target.value;
      let prevValue = JSON.parse(JSON.stringify(valGryphScore));
      if (val) {
        let formattedValue = val;
        let formattedValueParsed = parseInt(formattedValue);
        if (formattedValueParsed <= valGryphScore.end && formattedValue >= rangeGryphScore.start && formattedValue <= rangeGryphScore.end) {
          setValGryphScore({ start: formattedValueParsed, end: prevValue.end });
        } else {
          setValGryphScore({ start: 0, end: prevValue.end });
        }
      } else {
        setValGryphScore({ start: 0, end: prevValue.end });
      }
    }, 500);
  };

  const handleGryphScoreEndChange = (e) => {
    clearTimeout(timerObj);
    let formattedValue = e.floatValue;
    const prevValue = JSON.parse(JSON.stringify(valGryphScore));
    if (
      formattedValue &&
      formattedValue !== prevValue.end &&
      formattedValue >= valGryphScore.start &&
      formattedValue >= rangeGryphScore.start &&
      formattedValue <= rangeGryphScore.end
    ) {
      timerObj = setTimeout(() => {
        const formattedValueParsed = parseInt(formattedValue);
        setValGryphScore({ start: prevValue.start, end: formattedValueParsed });
      }, 500);
    }
  };

  const blurGryphScoreEndChange = (e) => {
    clearTimeout(timerObj);
    timerObj = setTimeout(() => {
      let val = e.target.value;
      let prevValue = JSON.parse(JSON.stringify(valGryphScore));
      if (val) {
        let formattedValue = val;
        let formattedValueParsed = parseInt(formattedValue);
        if (formattedValueParsed >= valGryphScore.start && formattedValue >= rangeGryphScore.start && formattedValue <= rangeGryphScore.end) {
          setValGryphScore({ start: prevValue.start, end: formattedValueParsed });
        } else {
          setValGryphScore({ start: prevValue.start, end: rangeGryphScore.end });
        }
      } else {
        setValGryphScore({ start: prevValue.start, end: rangeGryphScore.end });
      }
    }, 500);
  };
  //

  const getDecimalValue = (n) => {
    n = parseFloat(n).toFixed(2);
    let decimalValue = (n + "")?.split(".")[1]?.slice(0, 2);
    let value = parseInt((decimalValue / 100) * 60);
    if (isNaN(value)) {
      return 0;
    } else {
      return value;
    }
  };

  const getSpeakDuration = (participants) => {
    let res = participants.filter((e) => (e.custom_role === "HUMAN_AGENT") & (e.name !== "Customer"));
    return res[0]?.speaker_metrics[0]?.speakDuration;
  };

  const getSpeakPercent = (participants) => {
    let res = participants.filter((e) => (e.custom_role === "HUMAN_AGENT") & (e.name !== "Customer"));
    return res[0]?.speaker_metrics[0]?.speakPercent;
  };

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  };

  const countOccurrences = (string, input) => {
    const escapedString = escapeRegExp(string);
    const regex = new RegExp(escapedString, "g");
    const occurrences = (input?.match(regex) || []).length;
    return occurrences;
  };

  return endpoint.length === 0 || indexEnv.length === 0 || userInfo.reportLevel === "" ? (
    <div>
      <br></br>
      <h4>Fetching Data...</h4>
    </div>
  ) : (
    <ReactiveBase className={styles.baseDiv} url={endpoint} app={indexName}>
      <Row gutter={16} className={styles.rowDiv}>
        <Col span={6} style={{ overflow: "auto", maxHeight: "100%" }}>
          <Card
            headStyle={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
            title="Filter Results"
            type="inner"
            bordered={false}
            className={styles.facets}
            style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
          >
            <div>{statsCount} results found</div>
            <Divider className={styles.elasticSearchDivider}></Divider>
            {userInfo.reportLevel === "gryphon" ? (
              <>
                <MultiDropdownList
                  componentId="ClientName"
                  dataField="ClientName"
                  sortBy="asc"
                  showSearch={true}
                  className={styles.dropdownTitle}
                  size={100000}
                  style={{
                    marginBottom: "16px",
                  }}
                  queryFormat="or"
                  title="Client Name"
                  renderItem={(label, count, isSelected) => (
                    <div style={{ width: "100%" }}>
                      {label}
                      <span
                        style={{
                          float: "right",
                          textAlign: "right",
                          overflow: "hidden",
                          whiteSpace: "normal",
                          color: isSelected ? "red" : "dodgerblue",
                        }}
                      >
                        {count}
                      </span>
                    </div>
                  )}
                  renderNoResults={() => (
                    <div>
                      <h5 style={{ fontSize: "initial" }}>Client Name</h5>
                      <br></br>No Results Found
                    </div>
                  )}
                  react={{
                    and: componentIdsSync.filter((item) => item !== "ClientName"),
                  }}
                  URLParams={false}
                  defaultQuery={initialQuery}
                />
                <Divider className={styles.elasticSearchDivider}></Divider>
              </>
            ) : (
              <></>
            )}
            <MultiDropdownList
              componentId="AgentName"
              dataField="AgentName"
              sortBy="asc"
              showSearch={true}
              size={100000}
              style={{
                marginBottom: 16,
              }}
              className={styles.dropdownTitle}
              queryFormat="or"
              title="Agent Name"
              renderItem={(label, count, isSelected) => (
                <div style={{ width: "100%" }}>
                  {label}
                  <span
                    style={{
                      float: "right",
                      textAlign: "right",
                      overflow: "hidden",
                      whiteSpace: "normal",
                      color: isSelected ? "red" : "dodgerblue",
                    }}
                  >
                    {count}
                  </span>
                </div>
              )}
              renderNoResults={() => (
                <div>
                  <h5 style={{ fontSize: "initial" }}>Agent Name</h5>
                  <br></br>No Results Found
                </div>
              )}
              react={{
                and: componentIdsSync.filter((item) => item !== "AgentName"),
              }}
              URLParams={false}
              defaultQuery={initialQuery}
            />
            <Divider className={styles.elasticSearchDivider}></Divider>
            <MultiDropdownList
              componentId="CallDisposition"
              dataField="CallDisposition"
              className={styles.dropdownTitle}
              size={100000}
              style={{
                marginBottom: 16,
              }}
              sortBy="asc"
              showSearch={true}
              title="Activity Disposition"
              renderItem={(label, count, isSelected) => (
                <div style={{ width: "100%" }}>
                  {label}
                  <span
                    style={{
                      float: "right",
                      textAlign: "right",
                      overflow: "hidden",
                      whiteSpace: "normal",
                      color: isSelected ? "red" : "dodgerblue",
                    }}
                  >
                    {count}
                  </span>
                </div>
              )}
              renderNoResults={() => (
                <div>
                  <h5 style={{ fontSize: "initial" }}>Activity Disposition</h5>
                  <br></br>No Results Found
                </div>
              )}
              react={{
                and: componentIdsSync.filter((item) => item !== "CallDisposition"),
              }}
              URLParams={false}
              defaultQuery={initialQuery}
            />
            <Divider className={styles.elasticSearchDivider}></Divider>
            <div>
              <RangeSlider
                componentId="GryphScore"
                dataField="CallG1Scores.G1Score"
                title="Gryph Score"
                innerProps={{ histogram: { height: "40px !imporant" } }}
                className={styles.rangeInput}
                range={rangeGryphScore}
                defaultValue={rangeGryphScore}
                value={valGryphScore}
                onChange={handleGryphScoreRangeValueChange}
                showHistogram={true}
                showFilter={true}
                includeNullValues={false}
                innerClass={{
                  label: styles.rangeSliderLabels,
                  slider: styles.rangeSlider,
                }}
                customQuery={function () {
                  return {
                    query: {
                      range: {
                        "CallG1Scores.G1Score": {
                          gte: valGryphScore.start,
                          lte: valGryphScore.end,
                        },
                      },
                    },
                  };
                }}
              />
              <div className={styles.divRangeInputs}>
                <NumberFormat
                  inputMode="numeric"
                  displayType="input"
                  placeholder={rangeGryphScore.start.toString()}
                  variant="outlined"
                  label="Start"
                  value={valGryphScore.start}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  min={rangeGryphScore.start}
                  max={rangeGryphScore.end}
                  allowNegative={false}
                  inputProps={{ pattern: constants.REGEX_ONLY_DIGITS }}
                  onValueChange={handleGryphScoreStartChange}
                  onBlur={blurGryphScoreStartChange}
                  customInput={TextField}
                  isAllowed={(values) => {
                    if (!values.value) return true;
                    const { floatValue } = values;
                    return floatValue <= rangeGryphScore.end;
                  }}
                />
                <span className={styles.spanRangeInputs}>-</span>
                <NumberFormat
                  inputMode="numeric"
                  displayType="input"
                  placeholder={rangeGryphScore.end.toString()}
                  variant="outlined"
                  label="End"
                  value={valGryphScore.end}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  min={rangeGryphScore.start}
                  max={rangeGryphScore.end}
                  allowNegative={false}
                  inputProps={{ pattern: constants.REGEX_ONLY_DIGITS }}
                  onValueChange={handleGryphScoreEndChange}
                  onBlur={blurGryphScoreEndChange}
                  customInput={TextField}
                  isAllowed={(values) => {
                    if (!values.value) return true;
                    const { floatValue } = values;
                    return floatValue <= rangeGryphScore.end;
                  }}
                />
              </div>
            </div>
            <Divider className={styles.elasticSearchDivider}></Divider>
            <MultiDropdownList
              componentId="KPI"
              dataField="sentences.KPI.value.keyword"
              className={styles.dropdownTitle}
              showSearch={true}
              size={100000}
              style={{
                marginBottom: 16,
              }}
              queryFormat="and"
              title="Key Moments"
              renderItem={(label, count, isSelected) => (
                <div style={{ width: "100%" }}>
                  {label}
                  <span
                    style={{
                      float: "right",
                      textAlign: "right",
                      overflow: "hidden",
                      whiteSpace: "normal",
                      color: isSelected ? "red" : "dodgerblue",
                    }}
                  >
                    {count}
                  </span>
                </div>
              )}
              renderNoResults={() => (
                <div>
                  <h5 style={{ fontSize: "initial" }}>Key Moments</h5>
                  <br></br>No Results Found
                </div>
              )}
              react={{
                and: componentIdsSync.filter((item) => item !== "KPI"),
              }}
              URLParams={false}
              defaultQuery={initialQuery}
            />
            <Divider className={styles.elasticSearchDivider}></Divider>
            <MultiDropdownList
              componentId="Account"
              dataField="AccountName"
              className={styles.dropdownTitle}
              size={100000}
              style={{
                marginBottom: 16,
              }}
              sortBy="asc"
              showSearch={true}
              title="Account Name"
              renderItem={(label, count, isSelected) => (
                <div style={{ width: "100%" }}>
                  {label}
                  <span
                    style={{
                      float: "right",
                      textAlign: "right",
                      overflow: "hidden",
                      whiteSpace: "normal",
                      color: isSelected ? "red" : "dodgerblue",
                      fontSize: isSelected ? "large" : "inherit",
                    }}
                  >
                    {count}
                  </span>
                </div>
              )}
              renderNoResults={() => (
                <div>
                  <h5 style={{ fontSize: "initial" }}>Account Name</h5>
                  <br></br>No Results Found
                </div>
              )}
              react={{
                and: componentIdsSync.filter((item) => item !== "Account"),
              }}
              URLParams={false}
              defaultQuery={initialQuery}
            />
            <Divider className={styles.elasticSearchDivider}></Divider>
            <MultiDropdownList
              componentId="Opportunity"
              dataField="OpportunityName"
              className={styles.dropdownTitle}
              size={100000}
              style={{
                marginBottom: 16,
              }}
              sortBy="asc"
              showSearch={true}
              title="Opportunity Name"
              renderItem={(label, count, isSelected) => (
                <div style={{ width: "100%" }}>
                  {label}
                  <span
                    style={{
                      float: "right",
                      textAlign: "right",
                      overflow: "hidden",
                      whiteSpace: "normal",
                      color: isSelected ? "red" : "dodgerblue",
                    }}
                  >
                    {count}
                  </span>
                </div>
              )}
              renderNoResults={() => (
                <div style={{ marginBottom: 20 }}>
                  <h5 style={{ fontSize: "initial" }}>Opportunity Name</h5>
                  <br></br>No Results Found
                </div>
              )}
              react={{
                and: componentIdsSync.filter((item) => item !== "Opportunity"),
              }}
              URLParams={false}
              defaultQuery={initialQuery}
            />

            <Accordion
              style={{
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
                position: "absolute",
                width: "100%",
                left: "0px",
                padding: "4px 12px 4px 12px",
                background: "#FAFAFA",
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <h5 style={{ fontSize: "16px" }}>Additional Filters</h5>
              </AccordionSummary>
              <Card bordered={false}>
                <AccordionDetails sx={{ padding: 0, marginBottom: 20 }}>
                  <MultiDropdownList
                    componentId="Stage"
                    dataField="OpportunityStage"
                    className={styles.dropdownTitle}
                    showSearch={true}
                    size={100000}
                    style={{
                      marginBottom: 16,
                    }}
                    title="Opportunity Stage"
                    renderItem={(label, count, isSelected) => (
                      <div style={{ width: "100%" }}>
                        {label}
                        <span
                          style={{
                            float: "right",
                            textAlign: "right",
                            overflow: "hidden",
                            whiteSpace: "normal",
                            color: isSelected ? "red" : "dodgerblue",
                          }}
                        >
                          {count}
                        </span>
                      </div>
                    )}
                    renderNoResults={() => (
                      <div>
                        <h5 style={{ fontSize: "initial" }}>Opportunity Stage</h5>
                        <br></br>No Results Found
                      </div>
                    )}
                    react={{
                      and: componentIdsSync.filter((item) => item !== "Stage"),
                    }}
                    URLParams={false}
                    defaultQuery={initialQuery}
                  />
                  <Divider className={styles.elasticSearchDivider}></Divider>
                  <MultiDropdownList
                    componentId="CampaignName"
                    dataField="CampaignName"
                    className={styles.dropdownTitle}
                    showSearch={true}
                    size={100000}
                    style={{
                      marginBottom: 16,
                    }}
                    sortBy="asc"
                    title="Campaign Name"
                    renderItem={(label, count, isSelected) => (
                      <div style={{ width: "100%" }}>
                        {label}
                        <span
                          style={{
                            float: "right",
                            textAlign: "right",
                            overflow: "hidden",
                            whiteSpace: "normal",
                            color: isSelected ? "red" : "dodgerblue",
                          }}
                        >
                          {count}
                        </span>
                      </div>
                    )}
                    renderNoResults={() => (
                      <div>
                        <h5 style={{ fontSize: "initial" }}>Campaign Name</h5>
                        <br></br>No Results Found
                      </div>
                    )}
                    react={{
                      and: componentIdsSync.filter((item) => item !== "CampaignName"),
                    }}
                    URLParams={false}
                    showMissing={true}
                    defaultQuery={initialQuery}
                  />
                  <Divider className={styles.elasticSearchDivider}></Divider>
                  <MultiDropdownList
                    componentId="CallSource"
                    dataField="CallSource"
                    className={styles.dropdownTitle}
                    size={100000}
                    style={{
                      marginBottom: 16,
                    }}
                    showSearch={true}
                    title="Call Source"
                    renderItem={(label, count, isSelected) => (
                      <div style={{ width: "100%" }}>
                        {label}
                        <span
                          style={{
                            float: "right",
                            textAlign: "right",
                            overflow: "hidden",
                            whiteSpace: "normal",
                            color: isSelected ? "red" : "dodgerblue",
                            fontSize: isSelected ? "large" : "inherit",
                          }}
                        >
                          {count}
                        </span>
                      </div>
                    )}
                    renderNoResults={() => (
                      <div>
                        <h5 style={{ fontSize: "initial" }}>Call Source</h5>
                        <br></br>No Results Found
                      </div>
                    )}
                    react={{
                      and: componentIdsSync.filter((item) => item !== "CallSource"),
                    }}
                    URLParams={false}
                    defaultQuery={initialQuery}
                  />
                  <Divider className={styles.elasticSearchDivider}></Divider>
                  <MultiDropdownList
                    componentId="Speaker"
                    dataField="CallParticipants.custom_role"
                    nestedField="CallParticipants"
                    className={styles.dropdownTitle}
                    showSearch={true}
                    title="Speaker"
                    renderItem={(label, count, isSelected) => (
                      <div style={{ width: "100%" }}>
                        {label}
                        <span
                          style={{
                            float: "right",
                            textAlign: "right",
                            overflow: "hidden",
                            whiteSpace: "normal",
                            color: isSelected ? "red" : "dodgerblue",
                            fontSize: isSelected ? "large" : "inherit",
                          }}
                        >
                          {count}
                        </span>
                      </div>
                    )}
                    renderNoResults={() => (
                      <div>
                        <h5 style={{ fontSize: "initial" }}>Speaker</h5>
                        <br></br>No Results Found
                      </div>
                    )}
                    react={{
                      and: componentIdsSync.filter((item) => item !== "Speaker"),
                    }}
                    defaultQuery={initialQuery}
                  />
                  <Divider className={styles.elasticSearchDivider}></Divider>
                  <MultiDropdownList
                    componentId="ParticipantName"
                    dataField="CallParticipants.name"
                    nestedField="CallParticipants"
                    className={styles.dropdownTitle}
                    showSearch={true}
                    sortBy="asc"
                    title="Participant Name"
                    renderItem={(label, count, isSelected) => (
                      <div style={{ width: "100%" }}>
                        {label}
                        <span
                          style={{
                            float: "right",
                            textAlign: "right",
                            overflow: "hidden",
                            whiteSpace: "normal",
                            color: isSelected ? "red" : "dodgerblue",
                            fontSize: isSelected ? "large" : "inherit",
                          }}
                        >
                          {count}
                        </span>
                      </div>
                    )}
                    renderNoResults={() => (
                      <div>
                        <h5 style={{ fontSize: "initial" }}>Participant Name</h5>
                        <br></br>No Results Found
                      </div>
                    )}
                    react={{
                      and: componentIdsSync.filter((item) => item !== "ParticipantName"),
                    }}
                    defaultQuery={initialQuery}
                  />
                  <Divider className={styles.elasticSearchDivider}></Divider>
                  <MultiDropdownList
                    componentId="HostName"
                    dataField="HostName"
                    className={styles.dropdownTitle}
                    sortBy="asc"
                    showSearch={true}
                    title="Host Name"
                    renderItem={(label, count, isSelected) => (
                      <div style={{ width: "100%" }}>
                        {label}
                        <span
                          style={{
                            float: "right",
                            textAlign: "right",
                            overflow: "hidden",
                            whiteSpace: "normal",
                            color: isSelected ? "red" : "dodgerblue",
                            fontSize: isSelected ? "large" : "inherit",
                          }}
                        >
                          {count}
                        </span>
                      </div>
                    )}
                    renderNoResults={() => (
                      <div>
                        <h5 style={{ fontSize: "initial" }}>Host Name</h5>
                        <br></br>No Results Found
                      </div>
                    )}
                    react={{
                      and: componentIdsSync.filter((item) => item !== "HostName"),
                    }}
                    defaultQuery={initialQuery}
                  />
                  <Divider className={styles.elasticSearchDivider}></Divider>
                  <MultiDropdownList
                    componentId="Topic"
                    dataField="Topic"
                    className={styles.dropdownTitle}
                    showSearch={true}
                    title="Meeting Subject"
                    sortBy="asc"
                    renderItem={(label, count, isSelected) => (
                      <div style={{ width: "100%" }}>
                        {label}
                        <span
                          style={{
                            float: "right",
                            textAlign: "right",
                            overflow: "hidden",
                            whiteSpace: "normal",
                            color: isSelected ? "red" : "dodgerblue",
                            fontSize: isSelected ? "large" : "inherit",
                          }}
                        >
                          {count}
                        </span>
                      </div>
                    )}
                    renderNoResults={() => (
                      <div>
                        <h5 style={{ fontSize: "initial" }}>Meeting Subject</h5>
                        <br></br>No Results Found
                      </div>
                    )}
                    react={{
                      and: componentIdsSync.filter((item) => item !== "Topic"),
                    }}
                    defaultQuery={initialQuery}
                  />
                  <Divider className={styles.elasticSearchDivider}></Divider>
                  <MultiDropdownList
                    componentId="OfficeName"
                    dataField="officeName"
                    showSearch={true}
                    className={styles.dropdownTitle}
                    size={100000}
                    style={{
                      marginBottom: 16,
                    }}
                    sortBy="asc"
                    title="Office Name"
                    renderItem={(label, count, isSelected) => (
                      <div style={{ width: "100%" }}>
                        {label}
                        <span
                          style={{
                            float: "right",
                            textAlign: "right",
                            overflow: "hidden",
                            whiteSpace: "normal",
                            color: isSelected ? "red" : "dodgerblue",
                          }}
                        >
                          {count}
                        </span>
                      </div>
                    )}
                    renderNoResults={() => (
                      <div>
                        <h5 style={{ fontSize: "initial" }}>Office Name</h5>
                        <br></br>No Results Found
                      </div>
                    )}
                    react={{
                      and: componentIdsSync.filter((item) => item !== "OfficeName"),
                    }}
                    URLParams={false}
                    showMissing={true}
                    defaultQuery={initialQuery}
                  />
                  <Divider className={styles.elasticSearchDivider}></Divider>
                  <MultiDropdownList
                    componentId="RegionName"
                    dataField="regionName"
                    className={styles.dropdownTitle}
                    showSearch={true}
                    size={100000}
                    style={{
                      marginBottom: 16,
                    }}
                    sortBy="asc"
                    title="Region Name"
                    renderItem={(label, count, isSelected) => (
                      <div style={{ width: "100%" }}>
                        {label}
                        <span
                          style={{
                            float: "right",
                            textAlign: "right",
                            overflow: "hidden",
                            whiteSpace: "normal",
                            color: isSelected ? "red" : "dodgerblue",
                          }}
                        >
                          {count}
                        </span>
                      </div>
                    )}
                    renderNoResults={() => (
                      <div>
                        <h5 style={{ fontSize: "initial" }}>Region Name</h5>
                        <br></br>No Results Found
                      </div>
                    )}
                    react={{
                      and: componentIdsSync.filter((item) => item !== "RegionName"),
                    }}
                    URLParams={false}
                    showMissing={true}
                    defaultQuery={initialQuery}
                  />
                  <Divider className={styles.elasticSearchDivider}></Divider>
                  <MultiDropdownList
                    componentId="TeamName"
                    dataField="teamName"
                    className={styles.dropdownTitle}
                    showSearch={true}
                    size={100000}
                    style={{
                      marginBottom: 16,
                    }}
                    sortBy="asc"
                    title="Team Name"
                    renderItem={(label, count, isSelected) => (
                      <div style={{ width: "100%" }}>
                        {label}
                        <span
                          style={{
                            float: "right",
                            textAlign: "right",
                            overflow: "hidden",
                            whiteSpace: "normal",
                            color: isSelected ? "red" : "dodgerblue",
                          }}
                        >
                          {count}
                        </span>
                      </div>
                    )}
                    renderNoResults={() => (
                      <div>
                        <h5 style={{ fontSize: "initial" }}>Team Name</h5>
                        <br></br>No Results Found
                      </div>
                    )}
                    react={{
                      and: componentIdsSync.filter((item) => item !== "TeamName"),
                    }}
                    URLParams={false}
                    showMissing={true}
                    defaultQuery={initialQuery}
                  />
                  <Divider className={styles.elasticSearchDivider}></Divider>
                  {statsCount === 0 ? null : (
                    <>
                      <div>
                        <RangeSlider
                          componentId="CallDuration"
                          dataField="CallDuration"
                          title="Conversation Length (mins)"
                          innerProps={{ histogram: { height: "40px !imporant" } }}
                          className={styles.rangeInput}
                          range={rangeConvLength}
                          defaultValue={rangeConvLength}
                          value={valConvLength}
                          onChange={handleConvLengthValueChange}
                          showHistogram={true}
                          showFilter={true}
                          innerClass={{
                            label: styles.rangeSliderLabels,
                            slider: styles.rangeSlider,
                          }}
                          customQuery={function () {
                            return {
                              query: {
                                range: {
                                  CallDuration: {
                                    gte: valConvLength.start,
                                    lte: valConvLength.end,
                                  },
                                },
                              },
                            };
                          }}
                        />
                        <div className={styles.divRangeInputs}>
                          <NumberFormat
                            inputMode="numeric"
                            displayType="input"
                            mask="_"
                            placeholder={rangeConvLength.start.toString()}
                            variant="outlined"
                            label="Start"
                            value={valConvLength.start}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            min={rangeConvLength.start}
                            max={rangeConvLength.end}
                            allowNegative={false}
                            inputProps={{ pattern: constants.REGEX_ONLY_DIGITS }}
                            onValueChange={handleStartTimeChange}
                            onBlur={blurStartTimeChange}
                            customInput={TextField}
                            isAllowed={(values) => {
                              if (!values.value) return true;
                              const { floatValue } = values;
                              return floatValue <= rangeConvLength.end;
                            }}
                          />
                          <span className={styles.spanRangeInputs}>-</span>
                          <NumberFormat
                            inputMode="numeric"
                            displayType="input"
                            mask="_"
                            placeholder={rangeConvLength.end.toString()}
                            variant="outlined"
                            label="End"
                            value={valConvLength.end}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            min={rangeConvLength.start}
                            max={rangeConvLength.end}
                            allowNegative={false}
                            inputProps={{ pattern: constants.REGEX_ONLY_DIGITS }}
                            onValueChange={handleEndTimeChange}
                            onBlur={blurEndTimeChange}
                            customInput={TextField}
                            isAllowed={(values) => {
                              if (!values.value) return true;
                              const { floatValue } = values;
                              return floatValue <= rangeConvLength.end;
                            }}
                          />
                        </div>
                      </div>
                      <Divider className={styles.elasticSearchDivider}></Divider>
                      <div>
                        <RangeSlider
                          componentId="Talktime"
                          dataField="CallParticipants.speaker_metrics.speakDuration"
                          nestedField="CallParticipants.speaker_metrics"
                          title="Talktime (mins)"
                          innerProps={{ histogram: { height: "40px !imporant" } }}
                          className={styles.rangeInput}
                          range={rangeTalktime}
                          defaultValue={rangeTalktime}
                          value={valTalktime}
                          onChange={handleTalktimeRangeValueChange}
                          showHistogram={true}
                          showFilter={true}
                          includeNullValues={false}
                          innerClass={{
                            label: styles.rangeSliderLabels,
                            slider: styles.rangeSlider,
                          }}
                          customQuery={function () {
                            return {
                              query: {
                                nested: {
                                  path: "CallParticipants",
                                  query: {
                                    bool: {
                                      must: [
                                        {
                                          match: {
                                            "CallParticipants.custom_role": "HUMAN_AGENT",
                                          },
                                        },
                                        {
                                          nested: {
                                            path: "CallParticipants.speaker_metrics",
                                            query: {
                                              range: {
                                                "CallParticipants.speaker_metrics.speakDuration": {
                                                  gte: valTalktime.start,
                                                  lte: valTalktime.end,
                                                },
                                              },
                                            },
                                          },
                                        },
                                      ],
                                      must_not: [
                                        {
                                          match: {
                                            "CallParticipants.name": "Customer",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                },
                              },
                            };
                          }}
                        />
                        <div className={styles.divRangeInputs}>
                          <NumberFormat
                            inputMode="numeric"
                            displayType="input"
                            mask="_"
                            placeholder={rangeTalktime.start.toString()}
                            variant="outlined"
                            label="Start"
                            value={valTalktime.start}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            min={rangeTalktime.start}
                            max={rangeTalktime.end}
                            allowNegative={false}
                            inputProps={{ pattern: constants.REGEX_ONLY_DIGITS }}
                            onValueChange={handleTalktimeStartChange}
                            onBlur={blurTalktimeStartChange}
                            customInput={TextField}
                            isAllowed={(values) => {
                              if (!values.value) return true;
                              const { floatValue } = values;
                              return floatValue <= rangeTalktime.end;
                            }}
                          />
                          <span className={styles.spanRangeInputs}>-</span>
                          <NumberFormat
                            inputMode="numeric"
                            displayType="input"
                            mask="_"
                            placeholder={rangeTalktime.end.toString()}
                            variant="outlined"
                            label="End"
                            value={valTalktime.end}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            min={rangeTalktime.start}
                            max={rangeTalktime.end}
                            allowNegative={false}
                            inputProps={{ pattern: constants.REGEX_ONLY_DIGITS }}
                            onValueChange={handleTalktimeEndChange}
                            onBlur={blurTalktimeEndChange}
                            customInput={TextField}
                            isAllowed={(values) => {
                              if (!values.value) return true;
                              const { floatValue } = values;
                              return floatValue <= rangeTalktime.end;
                            }}
                          />
                        </div>
                      </div>
                      <Divider className={styles.elasticSearchDivider}></Divider>
                      <div>
                        <RangeSlider
                          componentId="SpeakPercent"
                          dataField="CallParticipants.speaker_metrics.speakPercent"
                          nestedField="CallParticipants.speaker_metrics"
                          title="Speak Percent (%)"
                          innerProps={{ histogram: { height: "40px !imporant" } }}
                          className={styles.rangeInput}
                          range={rangeSpeakPercent}
                          defaultValue={rangeSpeakPercent}
                          value={valSpeakPercent}
                          onChange={handleSpeakPercentRangeValueChange}
                          showHistogram={true}
                          showFilter={true}
                          includeNullValues={false}
                          innerClass={{
                            label: styles.rangeSliderLabels,
                            slider: styles.rangeSlider,
                          }}
                          customQuery={function () {
                            return {
                              query: {
                                nested: {
                                  path: "CallParticipants",
                                  query: {
                                    bool: {
                                      must: [
                                        {
                                          match: {
                                            "CallParticipants.custom_role": "HUMAN_AGENT",
                                          },
                                        },
                                        {
                                          nested: {
                                            path: "CallParticipants.speaker_metrics",
                                            query: {
                                              range: {
                                                "CallParticipants.speaker_metrics.speakPercent": {
                                                  gte: valSpeakPercent.start,
                                                  lte: valSpeakPercent.end,
                                                },
                                              },
                                            },
                                          },
                                        },
                                      ],
                                      must_not: [
                                        {
                                          match: {
                                            "CallParticipants.name": "Customer",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                },
                              },
                            };
                          }}
                        />
                        <div className={styles.divRangeInputs}>
                          <NumberFormat
                            inputMode="numeric"
                            displayType="input"
                            mask="_"
                            placeholder={rangeSpeakPercent.start.toString()}
                            variant="outlined"
                            label="Start"
                            value={valSpeakPercent.start}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            min={rangeSpeakPercent.start}
                            max={rangeSpeakPercent.end}
                            allowNegative={false}
                            inputProps={{ pattern: constants.REGEX_ONLY_DIGITS }}
                            onValueChange={handleSpeakPercentStartChange}
                            onBlur={blurSpeakPercentStartChange}
                            customInput={TextField}
                            isAllowed={(values) => {
                              if (!values.value) return true;
                              const { floatValue } = values;
                              return floatValue <= rangeSpeakPercent.end;
                            }}
                          />
                          <span className={styles.spanRangeInputs}>-</span>
                          <NumberFormat
                            inputMode="numeric"
                            displayType="input"
                            mask="_"
                            placeholder={rangeSpeakPercent.end.toString()}
                            variant="outlined"
                            label="End"
                            value={valSpeakPercent.end}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            min={rangeSpeakPercent.start}
                            max={rangeSpeakPercent.end}
                            allowNegative={false}
                            inputProps={{ pattern: constants.REGEX_ONLY_DIGITS }}
                            onValueChange={handleSpeakPercentEndChange}
                            onBlur={blurSpeakPercentEndChange}
                            customInput={TextField}
                            isAllowed={(values) => {
                              if (!values.value) return true;
                              const { floatValue } = values;
                              return floatValue <= rangeSpeakPercent.end;
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </AccordionDetails>
              </Card>
            </Accordion>
          </Card>
        </Col>
        <Col span={18} style={{ height: "inherit" }}>
          <Card bodyStyle={{ height: "inherit" }} style={{ height: "inherit", borderRadius: "10px" }}>
            <Row style={{ height: "50px", display: "block" }}>
              <DataSearch
                dataField={["sentences.Content", "Notes", "EntireContent", "OpportunityId", "Description", "CallParticipants.email", "DialedNumber"]}
                componentId="Search"
                className={styles.searchBar}
                style={{ all: "unset" }}
                URLParams={false}
                highlight={true}
                customHighlight={() => ({
                  highlight: {
                    pre_tags: ["<mark>"],
                    post_tags: ["</mark>"],
                    fields: {
                      Description: {},
                      "sentences.Content": {},
                      "CallParticipants.email": {},
                      DialedNumber: {},
                      Notes: {},
                    },
                    number_of_fragments: 0,
                  },
                })}
                autosuggest={false}
                debounce={100}
                onValueChange={(value) => {
                  setUserInput(value);
                }}
                customQuery={changeQuery ? exactMatchQuery : wildCardQuery}
                searchOperators={true}
                placeholder="Search"
                renderError={(error) => (
                  <div>
                    Something went wrong!
                    <br />
                    Error details
                    <br />
                    {error}
                  </div>
                )}
                react={{
                  and: componentIdsSync.filter((item) => item !== "Search"),
                }}
              />
              <ReactiveComponent
                dataField="StartTimestamp"
                componentId="DateRange"
                defaultQuery={initialQuery}
                URLParams={false}
                filterLabel="Date"
                showFilter={true}
                react={{
                  and: componentIdsSync.filter((item) => item !== "DateRange"),
                }}
                render={({ setQuery }) => (
                  <ESDateRange
                    setQuery={setQuery}
                    refreshDate={refreshDate}
                    defaultFromDate={defaultFromDate}
                    defaultToDate={defaultToDate}
                    selectedFromDate={selectedFromDate}
                    selectedToDate={selectedToDate}
                    setSelectedFromDate={setSelectedFromDate}
                    setSelectedToDate={setSelectedToDate}
                  />
                )}
              />
            </Row>
            <Row style={{ height: "50px" }}>
              <Col span="22">
                <Box className="search-wrapper">
                  <Box className="search-button-wrapper">
                    <Switch
                      checked={changeQuery}
                      onClick={() => setChangeQuery(!changeQuery)}
                      className={styles.searchSwitch}
                      style={{ backgroundColor: changeQuery ? "#1976d2" : "" }}
                    />
                    <BootstrapTooltip title="Only show results matching the exact phrase" placement="top" arrow>
                      <h6 className="exact-match-keyword">Exact text match</h6>
                    </BootstrapTooltip>
                  </Box>
                </Box>
              </Col>
            </Row>
            <Row style={{ maxHeight: "50px" }}>
              <Col span="22">
                <SelectedFilters
                  resetToDefault={true}
                  onChange={(values) => {
                    if (values) {
                      setFilterBool(false);
                      for (const val in values) {
                        if (
                          values[val]?.value !== 0 &&
                          values[val]?.value !== null &&
                          values[val]?.value !== "" &&
                          values[val]?.value?.length !== 0
                        ) {
                          setFilterBool(true);
                        }
                      }
                    }
                  }}
                  className={styles.chips}
                  onClear={(component, values) => {
                    if (component === "CallDuration") {
                      const prevValue = JSON.parse(JSON.stringify(rangeConvLength));
                      setValConvLength({ start: prevValue.start, end: prevValue.end });
                    }
                    if (component === "Talktime") {
                      const prevValue = JSON.parse(JSON.stringify(rangeTalktime));
                      setValTalktime({ start: prevValue.start, end: prevValue.end });
                    }
                    if (component === "SpeakPercent") {
                      const prevValue = JSON.parse(JSON.stringify(rangeSpeakPercent));
                      setValSpeakPercent({ start: prevValue.start, end: prevValue.end });
                    }
                    if (component === "GryphScore") {
                      const prevValue = JSON.parse(JSON.stringify(rangeGryphScore));
                      setValGryphScore({ start: prevValue.start, end: prevValue.end });
                    }
                    if (component === "DateRange" || component === null) {
                      setSelectedFromDate(defaultFromDate);
                      setSelectedToDate(defaultToDate);
                      setRefreshDate(!refreshDate);
                    }
                    if (values && Object.keys(values).length === 3) {
                      setFilterBool(false);
                    }
                  }}
                />
              </Col>
            </Row>

            <ReactiveList
              componentId="resultCards"
              dataField="StartTimestamp"
              style={{ height: filterBool ? "calc(98% - 100px)" : "calc(98% - 75px)" }}
              sortBy="desc"
              pagination={true}
              size={10}
              paginationAt="bottom"
              showResultStats={true}
              URLParams={false}
              renderResultStats={(stats) => {
                setStatsCount(stats.numberOfResults);
                return `At least ${stats.numberOfResults} results`;
              }}
              defaultQuery={initialQuery}
              react={{
                and: componentIdsSync,
              }}
              render={({ loading, data }) => {
                if (loading) {
                  return (
                    <Box>
                      <LinearProgress color="primary" />
                    </Box>
                  );
                }
                return (
                  <ReactiveList.ResultCardsWrapper id="card" style={{ marginBottom: "28px", overflow: "auto", height: "inherit" }}>
                    {data.map((item, index) => (
                      <div key={item._id} className={styles.result_card}>
                        <Row>
                          <Col span={1} style={{ marginTop: "6px" }}>
                            {item.CallSource === "TelephonyAudio" ? (
                              <img className={styles.audioIcon} alt="Audio call" style={{ borderRadius: "20%" }}></img>
                            ) : (
                              <img
                                className={styles.videoIcon}
                                alt="Video call"
                                style={{ borderRadius: "20%", background: "rgba(28, 117, 188, 0.2)" }}
                              ></img>
                            )}
                          </Col>
                          <Col span={11} style={{ display: "grid", alignContent: "flex-start" }}>
                            <div style={{ color: "#000", width: "inherit", padding: "0px", height: "24px" }}>
                              <div style={{ lineHeight: "24px" }}>
                                {item?.AgentName || item?.ClientName || item?.DialedNumber ? (
                                  <>
                                    {item?.AgentName ? (
                                      <>
                                        <b>{item?.AgentName}</b> called{" "}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {constants.formatPhoneNumber(item?.DialedNumber)?.length === 1 ? (
                                      <React.Fragment>
                                        <b>{item.ClientName?.length > 40 ? item.ClientName.substr(0, 40) + "..." : item.ClientName}</b>
                                      </React.Fragment>
                                    ) : (
                                      <b>{item.DialedNumber ? constants.formatPhoneNumber(item.DialedNumber) : ""}</b>
                                    )}
                                  </>
                                ) : (
                                  <b>{"Call"}</b>
                                )}
                              </div>
                            </div>
                            <div style={{ color: "#000" }}>
                              <div>
                                {item.CallDisposition ? (
                                  <>
                                    Call outcome was <b>{item.CallDisposition}</b> <br />
                                  </>
                                ) : null}
                              </div>
                              <div>
                                <span>
                                  {constants.formatISOTimeToET(item?.StartTimestamp)} {"  "}
                                </span>
                                <span style={{ marginLeft: "6px" }}>
                                  {parseInt(item?.CallDuration || item?.CallDuration === 0 ? item?.CallDuration : item?.Duration)}m{" "}
                                  {getDecimalValue(item?.CallDuration || item?.CallDuration === 0 ? item?.CallDuration : item?.Duration)}s
                                </span>
                              </div>
                            </div>

                            {item.Description || item.AccountName || item.Notes ? (
                              <div style={{ minHeight: "36.5px", width: "100%" }}>
                                <div
                                  className={styles.moreDetails}
                                  style={{ color: "#40a9ff", display: "inline-block", fontSize: "15px" }}
                                  onClick={() => {
                                    setOpenDetails(index === activeId ? !openDetails : true);
                                    setActiveid(index);
                                  }}
                                >
                                  More Details{" "}
                                  <img
                                    alt=""
                                    src={activeId === index && openDetails ? lessIcon : moreIcon}
                                    style={{ height: "4px", width: "8px", marginLeft: "5px" }}
                                  />
                                </div>
                              </div>
                            ) : null}
                          </Col>
                          <Col span={1}>
                            <Divider type="vertical" style={{ height: "100%", backgroundColor: "#E2E2E2" }} />
                          </Col>
                          <Col span={11} style={{ display: "grid", alignContent: "space-between" }}>
                            <ResultCard.Title style={{ marginBottom: "4px", color: "#000", width: "inherit", padding: "0px" }}>
                              <div className={styles.AgentName}>
                                {item?.AgentName?.length > 0 && item?.AgentName ? (
                                  <React.Fragment>{item?.AgentName}</React.Fragment>
                                ) : (
                                  <React.Fragment>Host</React.Fragment>
                                )}
                                <BootstrapTooltip
                                  title={
                                    <div>
                                      Call Length: {parseInt(item?.CallDuration)}m {getDecimalValue(item?.CallDuration)}
                                      s
                                      <br />
                                      Talk Time:{" "}
                                      {item?.CallParticipants?.length > 0 && getSpeakDuration(item?.CallParticipants) ? (
                                        <div style={{ display: "inline-flex" }}>
                                          {parseInt(getSpeakDuration(item?.CallParticipants))}m{" "}
                                          {getDecimalValue(getSpeakDuration(item?.CallParticipants))}s
                                        </div>
                                      ) : (
                                        <React.Fragment>0m 0s</React.Fragment>
                                      )}
                                      {item?.CallParticipants?.length > 0 && getSpeakPercent(item?.CallParticipants) ? (
                                        <div style={{ display: "inline-flex", marginLeft: 5 }}>({getSpeakPercent(item?.CallParticipants)}%)</div>
                                      ) : (
                                        <React.Fragment></React.Fragment>
                                      )}
                                    </div>
                                  }
                                  placement="right-start"
                                  arrow
                                >
                                  <div>
                                    <TimerOutlinedIcon className={styles.timerIcon} />
                                  </div>
                                </BootstrapTooltip>
                                {item?.CallParticipants?.length > 0 && getSpeakPercent(item?.CallParticipants) ? (
                                  <div style={{ display: "inline-flex", color: "#2779C5" }}>({getSpeakPercent(item?.CallParticipants)}%)</div>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                <div className={styles.esScoreDiv}>
                                  <div
                                    style={{
                                      backgroundColor: constants.getG1ThresholdColor(item?.CallG1Scores?.G1Score ? item?.CallG1Scores?.G1Score : 0),
                                    }}
                                    className={styles.gryphScore}
                                  >
                                    <BootstrapTooltip title="Gryph Score" placement="top" arrow>
                                      {item?.CallG1Scores?.G1Score ? item?.CallG1Scores?.G1Score : 0}
                                    </BootstrapTooltip>
                                  </div>
                                </div>
                              </div>
                            </ResultCard.Title>
                            <ResultCard.Description>
                              {userInput?.length === 0 ? (
                                <div
                                  style={{
                                    highlight: "#53C8FF",
                                    overflow: "hidden",
                                    fontSize: "15px",
                                    WebkitBoxOrient: "vertical",
                                    WebkitLineClamp: "2",
                                    display: "-webkit-box",
                                    minHeight: "auto",
                                    overflowWrap: "anywhere",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: item.sentences[0]?.Content,
                                  }}
                                />
                              ) : item.Description === null ? (
                                <div>
                                  <div
                                    style={{
                                      maxHeight: "80px",
                                      highlight: "#53C8FF",
                                      fontSize: "15px",
                                      minHeight: "auto",
                                      overflowWrap: "anywhere",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        item["sentences.Content"]?.length > 220
                                          ? item["sentences.Content"].substr(0, 220) + "...."
                                          : item["sentences.Content"],
                                    }}
                                  />
                                  {countOccurrences(userInput, item["sentences.Content"]) > 1 ? (
                                    <p>+{countOccurrences(userInput, item["sentences.Content"])} occurences</p>
                                  ) : null}
                                </div>
                              ) : (
                                <div>
                                  <div
                                    style={{
                                      maxHeight: "80px",
                                      highlight: "#53C8FF",
                                      fontSize: "15px",
                                      minHeight: "auto",
                                      overflowWrap: "anywhere",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        item["sentences.Content"]?.length > 220
                                          ? item["sentences.Content"].substr(0, 220) + "...."
                                          : item["sentences.Content"],
                                    }}
                                  />
                                </div>
                              )}
                            </ResultCard.Description>
                            <div style={{ display: "inline-flex", width: "100%" }}>
                              {countOccurrences(userInput, item["sentences.Content"]) > 1 ? (
                                <div style={{ display: "grid", alignItems: "center", width: "50%", color: "#6A707E" }}>
                                  {`+${countOccurrences(userInput, item["sentences.Content"])}`} occurences
                                </div>
                              ) : null}
                              <div className={styles.openInPlayer}>
                                <StyledButton href={`${item._id}${conversationRedirect}`} target="_blank" startIcon={<PlayArrowRoundedIcon />}>
                                  Open in player
                                </StyledButton>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        {openDetails && index === activeId ? (
                          <>
                            <Row>
                              <Col span={1} />
                              <Col span={23}>
                                <Divider style={{ backgroundColor: "#E2E2E2", marginBottom: "10px", marginTop: "10px" }} />
                              </Col>
                            </Row>
                            <Row>
                              <Col span={1} />
                              {item.AccountName || item.Description ? (
                                <Col span={10}>
                                  <div className={styles.detailTiltles}>Account Name / Activity Contact</div>
                                  <div className={styles.descriptionBox}>
                                    {item.AccountName}
                                    <br />
                                    {item?.Description}
                                  </div>
                                </Col>
                              ) : null}
                              {item?.Notes ? (
                                <>
                                  {item?.AccountName && item?.Notes ? (
                                    <>
                                      <Col span={1}>
                                        <Divider type="vertical" style={{ height: "90%", backgroundColor: "#E2E2E2", marginTop: "4px" }} />
                                      </Col>
                                    </>
                                  ) : undefined}
                                  <Col span={11}>
                                    <div>
                                      <div className={styles.notesTitle}>Notes </div>
                                      <div
                                        className={styles.descriptionBox}
                                        dangerouslySetInnerHTML={{
                                          __html: item?.Notes,
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </>
                              ) : null}
                            </Row>
                          </>
                        ) : null}
                      </div>
                    ))}
                  </ReactiveList.ResultCardsWrapper>
                );
              }}
            />
          </Card>
        </Col>
      </Row>
    </ReactiveBase>
  );
}

const StyledButton = withStyles({
  root: {
    textTransform: "none !important",
    fontWeight: "400 !important",
    "&:hover": {
      borderRadius: "8px",
      color: "#1976d2",
    },
  },
})(Button);
